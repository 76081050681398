import { track } from "@amplitude/analytics-react-native";
import { MouseEventHandler, useCallback } from "react";
import { View } from "react-native";
import { Link } from "solito/link";
import { amp } from "src/api/amplitude";
import cursor from "src/constants/cursor";
import extractDomainName from "src/helpers/extractDomainName";
import { TouchableWrapperProps } from "../../types";

const LinkWrapper = ({
	onLayout,
	containerStyle,
	style,
	href,
	target,
	children,
	disabled,
	loading,
	onPress,
}: TouchableWrapperProps) => {
	const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
		(event) => {
			track(amp.link.click, {
				href,
				domainName: extractDomainName(href),
			});
			if (loading || disabled) {
				event.preventDefault();
			} else {
				onPress?.();
			}
		},
		[disabled, href, loading, onPress],
	);

	return (
		<View onLayout={onLayout} style={containerStyle}>
			<Link
				href={loading || disabled ? "/" : (href ?? "/")}
				target={target}
				onClick={handleClick}
				viewProps={{
					style,
				}}
				style={{
					cursor: loading || disabled ? cursor.notAllowed : cursor.pointer,
					textDecoration: "none",
				}}
			>
				{children}
			</Link>
		</View>
	);
};
export default LinkWrapper;
